@import "../base/variables";

$mdi-font-path: "~@mdi/font/fonts";

@import "~@mdi/font/scss/materialdesignicons";

body {
	margin: 0;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, monospace;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
}

.section {
	margin-bottom: 20px;
}

.label__tip {
	color: $-color-text-secondary;
}

.loader {
	background-color: rgba(220, 220, 220, 0.5);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 99999;
}