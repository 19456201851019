@import "../../../styles/base/variables";

#view {
	background-color: #F5F5F5;
	height: 100vh;

	.title {
		font-size: 24px;
		font-weight: bold;
		padding-bottom: 5px; }

	.subTitle {
		font-size: 12px;
		color: $--color-text-secondary; }

	.version-number {
		color: $--color-text-secondary;
		padding-left: 5px; }

	.version {
		color: #333333; }

	.cd-container {
		height: 100%; }

	.cd-header {
		background-color: $-color-secondary;
		height: 70px !important;
		color: $--color-white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 16px 60px;

		.logo {
			width: 129.22px;
			height: 40px; }

		.cd-header--content {
			margin-right: 100px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left-content {
				display: inline-flex;

				#menu {
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;

					.apps--icon {
						margin-right: 15px;
						font-size: 20px; } }

				#user {
					display: flex;
					justify-content: center;
					align-items: center;

					.user--icon {
						margin-right: 10px;
						font-size: 20px; }

					.user--name {
						cursor: pointer;
						margin-right: 10px;
						font-size: 16px;
						color: 	($--color-white !important); }

					.el-icon--right {
						color: 	($--color-white !important);
						cursor: pointer; }

					.el-dropdown-menu {
						min-width: (max-content	!important); }

					.el-dropdown-menu__item {
						padding: (0 20px 0 20px !important); }

					.icon-item {
						padding: 5px;
						font-size: 16px;
						color: $-color-secondary;
						vertical-align: sub; }

					.el-dropdown-menu__item--divided {
						margin-top: (10px !important); }

					.el-dropdown-menu__item--divided:before {
						margin: (0 -20px !important); } } } } }

	.subheader {
		background-color: $--color-white;
		height: 77px;
		display: flex;
		justify-content: center;

		.subheader--content {
			display: flex;
			max-width: $-max-width-view;
			width: 100%;
			margin: 15px 40px;
			justify-content: space-between;
			align-items: center;
			display: inline;

			h2 {
				margin: 5px 0 0 0; } } }

	.cd-footer {
		text-align: center;
		margin-top: 40px;
		display: flex;
		align-items: center;
		justify-content: center; }

	.cd-main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		z-index: 0;
		background-color: #F5F5F5;
		padding: 20px 40px 0 40px;
		min-height: 100%;

		.cd-main--content {
			display: flex;
			max-width: $-max-width-view;
			width: 100%;
			margin: 0 40px;
			flex-direction: row;
			flex-wrap: wrap; } }

	.cd-popover {
		height: fit-content; }

	.menu-modulos {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap; } }
