@import "../../../styles/base/variables";

#root{
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(../../../assets/bg-login.png) no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover; 
    background-size: cover;
    opacity: 1;
}

.container{
    display: flex;
    height: 100%;
    min-height: 100%;
    display: -webkit-flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    min-height: 100vh;
}
.inicial{    
    padding: 40px;
    margin: 20px;
    border: 1px solid rgba(0, 0, 0, .3);    
}

.logo{
    width: 350px;
    height: 108.35px;
}

.form-login{
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    background-color: #FFFFFF;
    width: 350px;
    height: 297px;
    margin: 30px;
    padding: 20px;
    justify-content: flex-start;
}

.version{
    font-size: 13px;
    color: #FFFFFF; 
}

.version-number{
    color: $--color-text-secondary;
    padding-left: 5px;
    display: inline;
}
    
.cd-input__icon{
    padding-right: 5px;
    font-size: 18px;
}

.cd-button{
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: center;
}
.cd-form-item__label{
    color: #333333 !important;
}