@import "../../../styles/base/variables";

.modulo {
    display: flex;
    min-width: 277.5px;
    min-height: 180px;
    border: 1px solid $--color-white;
    border-radius: 4px;
    align-items: flex-end;
    margin: 20px 20px 20px 0;
    cursor: pointer;

    .footer {
        display: flex;
        width: 100%;
        height: 90px;
        background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(0,0,0,1));
        align-items: flex-end;
        border-radius: 4px;

        .modulo-name {
            color: $--color-white;
            font-size: 18px;
            padding: 0 0 15px 15px;
            text-shadow: 0px 3px 6px #262626; }

        .lock-icon {
            color: white;
            padding: 0 0 10px 15px;
            font-size: 24px; } } }

.not-allowed {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    cursor: not-allowed; }
