@import "../../../styles/base/variables";
	
.container-filial{
    height: 100%;
    min-height: 100vh;

	.cd-header{
		color: $--color-white;
		display: flex;
		height: 70px !important;
		justify-content: center;
		align-items: center;
		padding: 16px 60px;
	
		.cd-header--content{
			margin-right: 100px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;	
		}
		
		#user{
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;			
		
			.user--icon{
				margin-right: 10px;
				font-size: 20px;
				color: $--color-white;
			}			
			
			.user--name{
				margin-right: 10px;	
				color: $--color-white;
				font-size:16px;			
			}
			
			.el-icon--right{
				color: 	($--color-white !important);
				cursor: pointer;
			}						

			.el-dropdown-menu{
				min-width: (max-content	!important);
			}
					
			.el-dropdown-menu__item{
				padding: (0 20px 0 20px !important);	
			}

			.icon-item{
				padding: 5px;
				font-size: 16px;
				color: $-color-secondary;
				vertical-align: sub;
			}						

			.el-dropdown-menu__item--divided{
				margin-top: (10px !important);
			}

			.el-dropdown-menu__item--divided:before{
				margin: (0 -20px !important);
			}			
		}
	}
	
	.form-filial{
		border: 1px solid #E0E0E0;
		border-radius: 4px;
		background-color: #FFFFFF;
		width: 350px;
		height: 350px;
		margin: 30px;
		padding: 20px;
		justify-content: flex-start;

		.form-content{
			margin-top: inherit;
		}

		.title-form{
			font-size: 24px;
			font-weight: bold;
			padding-bottom: 5px;
		}

		.subtitle-form{
			font-size: 12px;
			color: #777777;
		}
	}
	
	.version{
		font-size: 13px;
		color: #FFFFFF; 
	}
	
	.logo{
		width: 129.37px;
		height: 40.05px;
	}
	
	.version-number{
		color: $--color-text-secondary;
		padding-left: 5px;
		display: inline;
	}
	
	.cd-container{
		height: 93.5vh;
	}
	
	.cd-footer{
		text-align: center;
		margin-top: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}		
	
	.cd-main{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 0;
	}
				
	.cd-main--content{
		max-width: $-max-width-view;
		width: 100%;
		margin: 0 40px;
		display: flex;
		justify-content: center;
	}
}

			
