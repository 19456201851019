$--font-path: '~cria-ui-react/themes/src/fonts';

$--color-primary: #0A9A84;
$--color-info: #409EFF;

@import "~cria-ui-react/themes/src/index.scss";

@media only screen and (max-width: 660px) {
	.cd-step-bar > li > p {
		display: none
	}
	.cd-step-bar {
		min-height: auto;
	}
}

.cd-footer {
	color: #333333;
	font-size: 13px;
}

.cd-menu-item > i + span {
	margin-left: 5px;
}

.cd-menu-item > i {
	margin-right: 0 !important;
}

.cd-tag ~.cd-tag {
	margin-left: 4px;
}

.cd-button span i {
	margin-left: 5px;
}

.cd-breadcrumb {
	font-size: 12px;
	.cd-breadcrumb__inner a {
		font-weight: normal;
	}
}