.el-tabs__header {
  margin: 0 !important;
}

.componente-alertas {
  &_vazio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .item-alerta_container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background-color: #fafafa;
    }

    &.unread {
      background-color: #eaeaea;
    }

    .item-alerta_icon {
      .mdi {
        display: flex;
        color: #777;
      }
    }

    .item-alerta_text {
      padding: 0 5px;
      font-size: 13px;
      color: #777;
    }

    .item-alerta_date {
      font-size: 13px;
    }
  }

  .componente-alertas_footer {
    .componente-alertas_footer-ver-tudo {
      cursor: pointer;
      float: right;
      padding-top: 20px;
      color: #0a9a84;
    }
  }
}

.icone-alertas {
  .popup-alertas {
    width: 460px !important;
    margin-right: 10px;
    padding: 10px;
  }

  .btn-alertas_wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background: none;
    border: none;

    .mdi {
      display: flex;
    }

    .btn-alertas_titulo {
      padding: 0 5px;
    }
  }

  .popup-header {
    display: flex;
    justify-content: space-between;

    .popup-header_section {
      display: flex;
      align-items: center;

      .mdi {
        display: flex;
        color: #006461;
        font-size: 18px;
      }

      .popup-subtitle {
        font-size: 14px;
      }
    }
  }

  .mdi {
    font-size: 24px;
  }
}

.todos-alertas_wrapper {
  border: 0px solid #ccc;

  .todos-alertas_header {
    padding: 10px;
  }

  .todos-alertas_main {
    .todos-alertas_lista-wrapper {
      .todos-alertas_lista-pagination {
        margin: 20px;
      }
    }
  }

  .todos-alertas_footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;

    border-top: 1px solid #ccc;

    .todos-alertas_footer-btn {
      cursor: pointer;
      display: flex;
      align-items: center;

      margin: 0 5px;
      padding: 10px;
      border-radius: 4px;
      font-size: 16px;

      &.danger {
        background-color: #f56c6c;
        color: #fff;
        transition: background-color 0.3s;

        &:hover {
          background-color: lighten(#f56c6c, 5%);
        }
      }

      &.info {
        background-color: #909399;
        color: #fff;
        transition: background-color 0.3s;

        &:hover {
          background-color: lighten(#909399, 5%);
        }
      }

      .mdi {
        display: flex;
        padding: 0 5px;
      }
    }
  }
}

.modal-alerta-expandido_wrapper {
  .cd-modal__header {
    padding: 0;
  }
  .modal-alerta-expandido_content {
    padding: 20px;
  }

  .modal-alerta-expandido_footer {
    padding: 0;
  }

  .modal-alerta-expandido_informacao {
    word-break: break-word;
  }
}

.tabs-alertas {
  width: 98% !important;
}

.alerta-title {
  word-break: keep-all;
}

.icon-alerta {
  font-size: 20px !important;
}

.destaque {
  color: #a60f10;
}

.icon-message {
  width: 30px;
}

.text-alerta-expandido {
  margin-top: 8px !important;
}

.alerta-marcar-lido {
  cursor: pointer;
}

.show-alertas {
  text-align: right;
  font-weight: 400;
  color: #0a9a84;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.alerta-popover {
  cursor: pointer;
}

.alerta-nao-lido {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  font-size: 14px;
  line-height: 1.2;
  word-break: normal;
  max-width: 80% !important;

  i {
    margin-right: 8px;
  }
}

.dateToNow {
  position: absolute;
  right: 2.25rem;
  top: 0;
  color: #888888;
  font-size: 12px;
}

.cd-collapse-item__header,
.cd-collapse-item__content {
  border: 0 !important;
  position: relative;
}

.cd-collapse-item__content,
.MuiAccordionDetails-root {
  padding: 0 !important;

  .cd-collapse-item__content {
    padding: 0 1rem !important;
  }
}

.cd-collapse-item__content .cd-collapse-item__header {
  background: none !important;
}

.cd-collapse-item:last-child {
  margin: 0 !important;
}

.cd-collapse-item {
  border-bottom: 1px solid #ebebeb !important;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
  background: #f0f0f0 !important;
}

.MuiAccordionSummary-root.Mui-expanded,
.MuiAccordionSummary-expandIcon {
  min-height: auto !important;
}

.MuiIconButton-edgeEnd {
  margin: 0 !important;
}

.MuiAccordionDetails-root {
  flex-direction: column !important;
}

.MuiAccordionSummary-content {
  background: #f0f0f0 !important;
  color: #000000 !important;
  font-weight: 500 !important;
  margin: 0 !important;
  padding: 1rem 0.5rem !important;

  .alerts-by-day-count {
    color: #fff;
    background: #0a9a84;
    border-radius: 2px;
    padding: 0 0.5rem;
    margin-left: 0.25rem;
  }
}

.alerta-details {
  border: 0;
  padding: 0;
  background: transparent;
  text-decoration-line: underline;
  color: #0a9a84;
  margin: 0.5rem 0;
}

.detailsGrid {
  display: grid;
  grid-template-areas:
    "a a"
    "b c";

  strong {
    color: #403f3f;
    font-size: 14px;
  }

  span {
    font-weight: 400;
    color: #666666;
    margin-left: 0.25rem;
    max-width: 75%;
  }

  .fazendaText {
    grid-area: a;
  }
}

.alerta-title_ver_todos {
  white-space: "normal !important";
  text-align: "justify !important";
  cursor: "pointer";
  font-size: "14px";
  word-break: keep-all;
  width: 100%;
}

.alerta-data_ver_todos {
  font-size: "14px";
  width: 100%;
}

.contador-novos-alertas-enabled {
  margin-left: 5px;
  border: 1px solid #0a9a84;
  border-radius: 3px;
  background-color: #0a9a84;
  color: white;
  font-size: 13px;
  font-weight: normal;
}

.contador-novos-alertas-disabled {
  margin-left: 5px;
  border: 1px solid #b7e2da;
  border-radius: 3px;
  background-color: #e6f5f2;
  color: #0a9a84;
  font-size: 13px;
  font-weight: normal;
}
