@import "../../../styles/base/variables";


.menu-container {
    width: 82px;
    height: 70px;
    display: flex;
    flex-direction: column;
    margin: 7px;

    .mini-container {
        display: flex;
        border-radius: 10px;
        opacity: 1;
        background: #0A9A84 0% 0% no-repeat padding-box;
        width: 40px;
        height: 40px;
        align-self: center;
        justify-content: center;
        align-items: center;

        .sigla {
            font-size: 24px;
            color: $--color-white; } }

    .label {
        font-size: 11px;
        word-break: break-word;
        text-align: center;
        color: #333333; } }

